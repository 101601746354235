import { AppAttachment } from "./attachment.model";

export class AppMessage {
  constructor(
    public type: string,
    public sender: string,
    public title: string,
    public date: Date,
    public isUnread: boolean,
    public content: string,
    public messageId: number,
    public isAttachment: boolean,
    public requiresAsthmaTest: boolean,
    public requiredTestId: number,
    public attachmentList: AppAttachment[],
    public masterMessageId: number,
    public masterMessage: AppMessage,
    public directionToPatient: boolean,
    public threadTop: boolean,
    public crossCommunicationStatus: boolean,
    public registerId: number,
    public sentByPersonId: number,
    public sentToPersonId: number,
    public messageSendTime: Date,
  ) {
  }
}
